export default {
  calculateDistanceFromScreen(pxToCm, elementHeight) {
    return Math.round((elementHeight * pxToCm) / 0.84); // where 1/tan(40deg) = 0.84 so assuming 80deg fov;
  },

  calculateScreenHeightAngle(
    leftEye,
    rightEye,
    distanceFromScreen,
    elementHeight,
    pxToCm
  ) {
    var yDistance = Math.round(
      (pxToCm * (elementHeight - (rightEye.position.y + leftEye.position.y))) /
        2
    );

    return Math.round(
      (Math.asin(yDistance / distanceFromScreen) * 180) / Math.PI
    );
  },

  calculateHeightDifferenceInCm(firstPoint, secondPoint, pxToCm) {
    var yDistance =
      Math.round(firstPoint.position.y) - Math.round(secondPoint.position.y);
    return Math.round(pxToCm * yDistance);
  },

  calculateAbsoluteDistanceBetweenTwoPointsInCm(
    firstPoint,
    secondPoint,
    pxToCm
  ) {
    var aboluteDistance = this.getAbsoluteDistanceBetweenTwoPoints(
      firstPoint,
      secondPoint
    );
    return Math.round(pxToCm * aboluteDistance);
  },

  getAbsoluteDistanceBetweenTwoPoints(firstPoint, secondPoint) {
    var xDistance = firstPoint.position.x - secondPoint.position.x;
    var yDistance = firstPoint.position.y - secondPoint.position.y;
    return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
  },

  pixelToCm(keypoints) {
    //based on average distance between eyes
    var leftEye = keypoints.find(item => item.part === "leftEye");
    var rightEye = keypoints.find(item => item.part === "rightEye");

    var absDistance = this.getAbsoluteDistanceBetweenTwoPoints(
      rightEye,
      leftEye
    );

    return 6.25 / absDistance;
  }
};
