import "@tensorflow/tfjs";
import * as posenet from "@tensorflow-models/posenet";

export default {
  toTuple(position) {
    return [position.y, position.x];
  },

  drawPoint(ctx, y, x, r, fillColor, borderColor) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = borderColor;
    ctx.stroke();
  },

  drawSegment(pa, pb, color, scale, ctx) {
    ctx.beginPath();
    ctx.moveTo(pa[1] * scale, pa[0] * scale);
    ctx.lineTo(pb[1] * scale, pb[0] * scale);
    ctx.lineWidth = 5;
    ctx.strokeStyle = color;
    ctx.stroke();
  },

  drawSkeletonShoulders(keypoints, minConfidence, ctx, scale = 1) {
    if (
      keypoints[5].score < minConfidence ||
      keypoints[6].score < minConfidence
    ) {
      return;
    }

    this.drawSegment(
      this.toTuple(keypoints[5].position),
      this.toTuple(keypoints[6].position),
      "blue",
      scale,
      ctx
    );
  },

  drawSkeleton(keypoints, minConfidence, ctx, scale = 1) {
    const adjacentKeyPoints = posenet.getAdjacentKeyPoints(
      keypoints,
      minConfidence
    );
    adjacentKeyPoints.forEach(keypoints => {
      this.drawSegment(
        this.toTuple(keypoints[0].position),
        this.toTuple(keypoints[1].position),
        "blue",
        scale,
        ctx
      );
    });
  },

  drawKeypoints(keypoints, minConfidence, ctx, scale = 1) {
    for (let i = 0; i < keypoints.length; i++) {
      const keypoint = keypoints[i];

      if (keypoint.score < minConfidence) {
        continue;
      }

      const { y, x } = keypoint.position;
      this.drawPoint(ctx, y * scale, x * scale, 4, "white", "blue");
    }
  }
};
