<template>
  <v-row justify="center" align="center" class="canvas-sizing">
    <div
      class="output-container pa-0"
      :style="cameraAvailable ? 'height:100%' : ''"
    >
      <video ref="video" v-show="false" playsinline></video>
      <canvas ref="output" v-show="cameraAvailable" class="canvas-sizing" />
      <canvas ref="clickEffect" v-show="takeSnapshot" class="canvas-overlay" />
      <div
        ref="preventMeasureOverlay"
        v-show="preventMeasure && !freezeCapture"
        class="text-background-overlay"
      />
      <p
        v-show="preventMeasure && !freezeCapture"
        class="text-overlay text-h4 white--text"
      >
        {{ $t("deskAssessment.webcam.measurement.error.title") }}<br />
        <span class="text-subtitle-1">{{ $t(warningTextToShow) }}</span>
      </p>

      <div class="canvas-overlay d-flex align-center justify-center text-h2">
        <v-progress-circular
          v-show="countdownRunning && cameraAvailable"
          :rotate="270"
          :size="progressSize"
          :width="progressWidth"
          :value="countdownProgress"
          color="primary"
          >{{ countdownText }}</v-progress-circular
        >
      </div>
    </div>
    <v-col cols="12">
      <v-row justify="center">
        <v-btn
          color="secondary"
          rounded
          :disabled="(preventMeasure && !freezeCapture) || countdownRunning"
          @click="remeasureButtonClick"
          v-if="cameraAvailable && fixResults === false && snapshotTaken"
        >
          {{ $t("buttons.remeasure") }}
        </v-btn>
      </v-row>
    </v-col>

    <v-row
      v-show="!cameraAvailable && !cameraLoading"
      align="center"
      justify="center"
      style="height: 100%; width: 100%"
    >
      <v-card class="mx-auto" max-width="100%" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h4 mb-1">{{
              $t("trackedWebCamComponent.error.title")
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("trackedWebCamComponent.error.subtitle")
            }}</v-list-item-subtitle>
            <v-list-item-content>
              {{ $t("trackedWebCamComponent.error.content.1") }}
              <br />{{ $t("trackedWebCamComponent.error.content.2") }} <br />{{
                $t("trackedWebCamComponent.error.content.3")
              }}
            </v-list-item-content>
          </v-list-item-content>
          <v-list-item-avatar tile>
            <v-img src="@/assets/images/noWebcamIcon.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-row>

    <v-row
      v-show="cameraLoading"
      align="center"
      justify="center"
      style="height: 100%; width: 100%"
    >
      <v-progress-circular
        :class="$vuetify.breakpoint.smAndDown ? 'text-body-1' : 'text-h5'"
        indeterminate
        :size="progressSize"
        :width="progressWidth"
        color="secondary"
        >{{ loadingMessage }}</v-progress-circular
      >
    </v-row>

    <v-col cols="8" class="pa-0 ma-0" v-if="availableInputDevices.length > 1"
      ><v-select
        v-model="currentInputDeviceId"
        :items="availableInputDevices"
        item-text="label"
        :no-data-text="$t('trackedWebCamComponent.cameraDropDown.noCameras')"
        item-value="id"
        outlined
        prepend-inner-icon="mdi-camera"
        :label="$t('trackedWebCamComponent.cameraDropDown.label')"
        @change="loadCamera(currentInputDeviceId)"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import * as posenet from "@tensorflow-models/posenet";
import ProgressSizeConverter from "@/services/converters/circular-progress-size-converter";
import DrawingUtil from "@/services/tracking/posenet-drawing";
import DeskCalculations from "@/services/deskassessment/desk-calculations";
import PoseValidation from "@/services/tracking/posenet-pose-validation";

const guiState = {
  algorithm: "single-pose",
  input: {
    outputStride: 16,
    imageScaleFactor: 0.5
  },
  singlePoseDetection: {
    minPoseConfidence: 0.1,
    minPartConfidence: 0.5,
    maxPoseDetections: 1,
    nmsRadius: 20.0
  },
  net: null,
  animationId: 0
};

export default {
  name: "Camera",
  props: {
    fixResults: Boolean,
    snapshotTaken: Boolean,
    headAndShouldersOnly: Boolean,
    value: Object
  },
  data() {
    return {
      poseNetResults: {
        distanceFromScreen: 0,
        screenHeightAngle: 0,
        shoulderHeightDifference: 0,
        rightShoulderHigher: false,
        leftShoulderToEarDistance: 0,
        rightShoulderToEarDistance: 0,
        rightShoulderToEarLonger: false,
        shoulderToEarDifference: 0,
        capturedImage: null,
        pictureTaken: false
      },
      width: 800,
      height: 500,
      cameraLoading: true,
      cameraAvailable: false,
      countdownRunning: false,
      countdownValue: 3,
      countdownProgress: 100,
      countdownCallback: null,
      takeSnapshot: false,
      captureFrozen: false,
      snapshotCallback: null,
      currentInputDeviceId: null,
      availableInputDevices: [],
      stopAnimationFrame: false,
      detectInputDeviceID: null,
      preventCameraListRefresh: false,
      preventMeasure: false,
      skeletonError: false,
      eyesInView: false
    };
  },
  mounted() {
    this.getAllCameraDevices();
    this.detectInputDeviceID = setInterval(this.getAllCameraDevices, 3000);
    this.onMounted();
  },
  beforeDestroy() {
    clearInterval(this.detectInputDeviceID);

    this.stopCurrentVideo();

    const canvas = this.$refs.output;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, this.width, this.height);
  },
  computed: {
    ...mapGetters(["alternativeWebcamErrorMessage"]),
    showCountdown() {
      return this.cameraAvailable && this.countdownRunning;
    },
    countdownText() {
      return Math.ceil(this.countdownValue) === 0
        ? ""
        : Math.ceil(this.countdownValue);
    },
    countdownSize() {
      this.$refs.output;
      return 100;
    },
    progressSize() {
      return ProgressSizeConverter.convertSize(
        this.$vuetify.breakpoint.name,
        1.4
      );
    },
    progressWidth() {
      return ProgressSizeConverter.convertWidth(
        this.$vuetify.breakpoint.name,
        0.5
      );
    },
    loadingMessage() {
      return this.$vuetify.breakpoint.smAndDown
        ? this.$t("trackedWebCamComponent.loading.smallScreen")
        : this.$t("trackedWebCamComponent.loading.bigScreen");
    },
    freezeCapture: {
      get() {
        return this.captureFrozen;
      },
      set(value) {
        this.captureFrozen = value;
        this.$emit("snapshotIsDisplayed", value);
      }
    },
    warningTextToShow() {
      if (!this.eyesInView) {
        return this.alternativeWebcamErrorMessage
          ? "deskAssessment.webcam.measurement.error.issue.alternativeGenericError"
          : "deskAssessment.webcam.measurement.error.issue.noEyes";
      } else if (this.skeletonError) {
        return "deskAssessment.webcam.measurement.error.issue.invalidSkeleton";
      }
    }
  },
  methods: {
    stopCurrentVideo() {
      this.stopAnimationFrame = true;
      window.cancelAnimationFrame(guiState.animationId);
      let video = this.$refs.video;
      if (video.srcObject != null) {
        video.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
      }

      if (window.stream) {
        window.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    getAllCameraDevices() {
      if (this.preventCameraListRefresh) {
        return;
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          let tempDeviceArray = [];

          devices.forEach(device => {
            if (device.kind === "videoinput") {
              tempDeviceArray.push({
                label:
                  device.label != ""
                    ? device.label
                    : this.$t(
                        "trackedWebCamComponent.cameraDropDown.prefixText"
                      ) +
                      (tempDeviceArray.length + 1),
                id: device.deviceId
              });
            }
          });

          // repopulate the available devices array if some exist, otherwise reset camera and devices.
          if (tempDeviceArray.length > 0) {
            this.availableInputDevices = tempDeviceArray;
            // if current input device has been set and is not existent in array, call load camera on first entry in array
            if (
              this.currentInputDeviceId &&
              tempDeviceArray.filter(e => e.id === this.currentInputDeviceId)
                .length === 0
            ) {
              this.currentInputDeviceId = tempDeviceArray[0].id;
              this.loadCamera(tempDeviceArray[0].id);
            }
          } else {
            this.currentInputDeviceId = null;
            this.stopCurrentVideo();
            this.availableInputDevices = [];
          }
        })
        .catch(err => {});
    },
    async setupCamera(currentId) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error(
          "Browser API navigator.mediaDevices.getUserMedia not available"
        );
      }
      const video = this.$refs.video;
      video.width = this.width;
      video.height = this.height;

      this.preventCameraListRefresh = true;
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: currentId ? { exact: currentId } : undefined,
          width: this.width,
          height: this.height
        }
      });
      this.preventCameraListRefresh = false;
      this.getAllCameraDevices();
      window.stream = stream;
      video.srcObject = stream;
      return new Promise(resolve => {
        video.onloadedmetadata = () => {
          resolve(video);
        };
      });
    },
    async loadVideo(cameraId) {
      const video = await this.setupCamera(cameraId);
      video.play();
      return video;
    },
    async loadCamera(cameraId) {
      this.stopCurrentVideo();
      this.freezeCapture = false;
      this.stopAnimationFrame = false;
      let video;
      try {
        video = await this.loadVideo(cameraId);
      } catch (e) {
        this.$logger.captureException(e);
        this.cameraAvailable = false;
        this.cameraLoading = false;
        this.$emit("cameraFailed");
        throw e;
      }
      this.cameraAvailable = true;
      this.cameraLoading = false;
      this.intialiseSnapshotCanvas();
      this.detectPoseInRealTime(video, this.$refs.output);
    },
    async onMounted() {
      // Load the PoseNet model
      const net = await posenet.load();
      guiState.net = net;
      await this.loadCamera(undefined);
    },
    detectPoseInRealTime(video, canvas) {
      let ctx = canvas.getContext("2d");
      let minPoseConfidence;
      let minPartConfidence;
      let pose;

      canvas.width = this.width;
      canvas.height = this.height;

      this.poseDetectionFrame(
        pose,
        minPoseConfidence,
        minPartConfidence,
        ctx,
        canvas,
        video
      );
    },
    async poseDetectionFrame(
      pose,
      minPoseConfidence,
      minPartConfidence,
      ctx,
      canvas,
      video
    ) {
      if (this.stopAnimationFrame) {
        return;
      }

      // Specific try catch to handle video element not loaded yet error - not ideal solution however when setting this to run after loadeddata event same error occurs.
      try {
        pose = await guiState.net.estimateSinglePose(
          video,
          guiState.input.imageScaleFactor,
          false,
          guiState.input.outputStride
        );
      } catch (err) {
        if (err.message.includes("The video element has not loaded data yet")) {
          return;
        }
      }

      if (this.headAndShouldersOnly) {
        pose.keypoints.splice(7, pose.keypoints.length - 7);
      }

      minPoseConfidence = guiState.singlePoseDetection.minPoseConfidence;
      minPartConfidence = guiState.singlePoseDetection.minPartConfidence;
      this.eyesInView = PoseValidation.areEyesInView(pose);
      this.skeletonError = false;
      if (this.eyesInView && PoseValidation.areShouldersInView(pose)) {
        this.skeletonError = !PoseValidation.isSkeletonValid(pose);
      }

      this.preventMeasure = !this.eyesInView || this.skeletonError;
      this.$emit("preventMeasure", this.preventMeasure);
      this.$emit("input", pose);

      if (!this.freezeCapture) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // draw the resulting skeleton and keypoints if over certain confidence scores
        if (pose.score >= minPoseConfidence) {
          if (this.headAndShouldersOnly) {
            DrawingUtil.drawSkeletonShoulders(
              pose.keypoints,
              minPartConfidence,
              ctx
            );
          } else {
            DrawingUtil.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
          }
          DrawingUtil.drawKeypoints(pose.keypoints, minPartConfidence, ctx);

          this.CalculateValues(pose);
        }
      }
      this.sendResults();

      guiState.animationId = window.requestAnimationFrame(
        this.poseDetectionFrame.bind(
          window,
          pose,
          minPoseConfidence,
          minPartConfidence,
          ctx,
          canvas,
          video
        )
      );
    },

    CalculateValues(pose) {
      var pxToCm = DeskCalculations.pixelToCm(pose.keypoints);
      var leftEye = pose.keypoints.find(item => item.part === "leftEye");
      var rightEye = pose.keypoints.find(item => item.part === "rightEye");
      var leftShoulder = pose.keypoints.find(
        item => item.part === "leftShoulder"
      );
      var rightShoulder = pose.keypoints.find(
        item => item.part === "rightShoulder"
      );
      var leftEar = pose.keypoints.find(item => item.part === "leftEar");
      var rightEar = pose.keypoints.find(item => item.part === "rightEar");

      this.poseNetResults.distanceFromScreen =
        DeskCalculations.calculateDistanceFromScreen(pxToCm, this.height);
      this.poseNetResults.screenHeightAngle =
        DeskCalculations.calculateScreenHeightAngle(
          leftEye,
          rightEye,
          this.poseNetResults.distanceFromScreen,
          this.height,
          pxToCm
        );

      let shoulderDifference = DeskCalculations.calculateHeightDifferenceInCm(
        leftShoulder,
        rightShoulder,
        pxToCm
      );
      this.poseNetResults.rightShoulderHigher = shoulderDifference < 0;
      this.poseNetResults.shoulderHeightDifference =
        Math.abs(shoulderDifference);

      let bothEarsVisible = PoseValidation.areEarsInView(pose);
      this.poseNetResults.leftShoulderToEarDistance = bothEarsVisible
        ? DeskCalculations.calculateAbsoluteDistanceBetweenTwoPointsInCm(
            leftShoulder,
            leftEar,
            pxToCm
          )
        : null;
      this.poseNetResults.rightShoulderToEarDistance = bothEarsVisible
        ? DeskCalculations.calculateAbsoluteDistanceBetweenTwoPointsInCm(
            rightShoulder,
            rightEar,
            pxToCm
          )
        : null;

      this.poseNetResults.rightShoulderToEarLonger = bothEarsVisible
        ? this.poseNetResults.rightShoulderToEarDistance >
          this.poseNetResults.leftShoulderToEarDistance
        : null;
      this.poseNetResults.shoulderToEarDifference = bothEarsVisible
        ? Math.round(
            Math.abs(
              ((this.poseNetResults.leftShoulderToEarDistance -
                this.poseNetResults.rightShoulderToEarDistance) /
                this.poseNetResults.rightShoulderToEarDistance) *
                100
            )
          )
        : null;
    },
    captureImage() {
      const canvas = this.$refs.output;
      if (canvas) {
        this.poseNetResults.capturedImage = canvas.toDataURL("image/jpeg", 1.0);
        this.poseNetResults.pictureTaken = true;
      }
    },
    sendResults() {
      this.captureImage();
      this.$emit("showResults", this.poseNetResults);
    },
    startCountdown() {
      this.snapshotTaken;
      this.freezeCapture = false;
      this.countdownRunning = true;
      this.$emit("countdownRunning", true);
      this.countdownCallback = window.setInterval(this.updateCountdown, 500);
    },
    remeasureButtonClick() {
      this.$gtag.event("Remeasure webcam results", {
        event_category: "Desk Assessment - Webcam Page"
      });
      this.startCountdown();
    },
    updateCountdown() {
      this.countdownValue -= 0.5;
      if (!Number.isInteger(this.countdownValue)) {
        this.countdownProgress -= 33;
      }
      if (this.countdownValue <= 0) {
        window.clearInterval(this.countdownCallback);
        if (!this.preventMeasure) {
          this.takeSnapshot = true;
          this.snapshotCallback = window.setInterval(
            this.stopSnapshotEffect,
            100
          );
          this.$emit("snapshot", this.poseNetResults);
        }
        this.countdownRunning = false;
        this.$emit("countdownRunning", false);
        this.countdownValue = 3;
        this.countdownProgress = 100;
      }
    },
    intialiseSnapshotCanvas() {
      if (!this.$refs.clickEffect) {
        return;
      }
      var ctx = this.$refs.clickEffect.getContext("2d");
      ctx.beginPath();
      ctx.rect(0, 0, this.width, this.height);
      ctx.fillStyle = "black";
      ctx.fill();
    },
    stopSnapshotEffect() {
      window.clearInterval(this.snapshotCallback);
      this.takeSnapshot = false;
      this.freezeCapture = true;
    }
  }
};
</script>

<style scoped>
.output-container {
  position: relative;
  width: 80%;
}
.canvas-sizing {
  width: 100%;
  height: 100%;
}
.canvas-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.text-background-overlay {
  width: 100%;
  height: 98.5%;
  position: absolute;
  text-align: center;
  background-color: black;
  opacity: 0.6;
  left: 0;
  top: 0;
}
.text-overlay {
  width: 90%;
  height: 100%;
  position: absolute;
  text-align: center;
  left: 5%;
  top: 5%;
}
</style>
