var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticStyle: { "min-height": "425px" },
          attrs: { justify: "space-around", align: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("TrackedWebcam", {
                    ref: "webcam",
                    attrs: {
                      fixResults: _vm.fixResults,
                      snapshotTaken: _vm.snapshotTaken,
                      width: 600,
                      height: 500,
                      headAndShouldersOnly: true
                    },
                    on: {
                      showResults: _vm.showResults,
                      snapshot: function($event) {
                        return _vm.$emit("snapshot", $event)
                      },
                      preventMeasure: function($event) {
                        return _vm.updatePreventMeasureStatus($event)
                      },
                      snapshotIsDisplayed: function($event) {
                        _vm.snapshotIsDisplayed = $event
                      },
                      countdownRunning: function($event) {
                        return _vm.$emit("countdownRunning", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("webcam-information-card", {
                staticClass: "elevation-0",
                staticStyle: { width: "100%" },
                attrs: {
                  colour: _vm.screenDistanceColour,
                  imageUrl: "screenDistanceIcon",
                  fadedHeader: false,
                  headerText: _vm.screenDistanceOverviewText,
                  preValueText: _vm.screenDistancePreValueText,
                  valueText: _vm.screenDistanceValueText,
                  postValueText: _vm.screenDistancePostValueText,
                  hideValueText: _vm.screenDistanceColour == "green",
                  liveResults: "",
                  disabled: !_vm.showLiveResults,
                  showingResults: _vm.showingResults
                }
              }),
              _c("WebcamInformationCard", {
                staticClass: "elevation-0",
                staticStyle: { width: "100%" },
                attrs: {
                  colour: _vm.screenHeightColour,
                  imageUrl: "screenHeightIcon",
                  headerText: _vm.screenHeightOverviewText,
                  fadedHeader: false,
                  preValueText: _vm.screenHeightPreValueText,
                  valueText: _vm.screenHeightValueText,
                  postValueText: _vm.screenHeightPostValueText,
                  hideValueText: false,
                  liveResults: "",
                  disabled: !_vm.showLiveResults,
                  showingResults: _vm.showingResults
                }
              }),
              _c("WebcamInformationCard", {
                staticClass: "elevation-0",
                staticStyle: { width: "100%" },
                attrs: {
                  colour: _vm.shoulderToEarDifferenceColour,
                  imageUrl: "shoulderToEarIcon",
                  fadedHeader: false,
                  headerText: _vm.shoulderToEarDifferenceOverviewText,
                  preValueText: _vm.shoulderToEarDifferencePreValueText,
                  valueText: _vm.shoulderToEarDifferenceValueText,
                  postValueText: _vm.shoulderToEarDifferencePostValueText,
                  hideValueText: false,
                  liveResults: "",
                  disabled: !_vm.showLiveResults,
                  showingResults: _vm.showingResults
                }
              }),
              _c("WebcamInformationCard", {
                staticClass: "elevation-0",
                staticStyle: { width: "100%" },
                attrs: {
                  colour: _vm.shoulderHeightColour,
                  imageUrl: "shoulderAngleIcon",
                  fadedHeader: false,
                  headerText: _vm.shoulderDistanceOverviewText,
                  preValueText: _vm.shoulderDistancePreValueText,
                  valueText: _vm.shoulderDistanceValueText,
                  postValueText: _vm.shoulderDistancePostValueText,
                  hideValueText: false,
                  liveResults: "",
                  disabled: !_vm.showLiveResults,
                  showingResults: _vm.showingResults
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }