var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "canvas-sizing",
      attrs: { justify: "center", align: "center" }
    },
    [
      _c(
        "div",
        {
          staticClass: "output-container pa-0",
          style: _vm.cameraAvailable ? "height:100%" : ""
        },
        [
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "video",
            attrs: { playsinline: "" }
          }),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.cameraAvailable,
                expression: "cameraAvailable"
              }
            ],
            ref: "output",
            staticClass: "canvas-sizing"
          }),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.takeSnapshot,
                expression: "takeSnapshot"
              }
            ],
            ref: "clickEffect",
            staticClass: "canvas-overlay"
          }),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.preventMeasure && !_vm.freezeCapture,
                expression: "preventMeasure && !freezeCapture"
              }
            ],
            ref: "preventMeasureOverlay",
            staticClass: "text-background-overlay"
          }),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.preventMeasure && !_vm.freezeCapture,
                  expression: "preventMeasure && !freezeCapture"
                }
              ],
              staticClass: "text-overlay text-h4 white--text"
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("deskAssessment.webcam.measurement.error.title")
                  )
              ),
              _c("br"),
              _c("span", { staticClass: "text-subtitle-1" }, [
                _vm._v(_vm._s(_vm.$t(_vm.warningTextToShow)))
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "canvas-overlay d-flex align-center justify-center text-h2"
            },
            [
              _c(
                "v-progress-circular",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.countdownRunning && _vm.cameraAvailable,
                      expression: "countdownRunning && cameraAvailable"
                    }
                  ],
                  attrs: {
                    rotate: 270,
                    size: _vm.progressSize,
                    width: _vm.progressWidth,
                    value: _vm.countdownProgress,
                    color: "primary"
                  }
                },
                [_vm._v(_vm._s(_vm.countdownText))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _vm.cameraAvailable &&
              _vm.fixResults === false &&
              _vm.snapshotTaken
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        disabled:
                          (_vm.preventMeasure && !_vm.freezeCapture) ||
                          _vm.countdownRunning
                      },
                      on: { click: _vm.remeasureButtonClick }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.remeasure")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.cameraAvailable && !_vm.cameraLoading,
              expression: "!cameraAvailable && !cameraLoading"
            }
          ],
          staticStyle: { height: "100%", width: "100%" },
          attrs: { align: "center", justify: "center" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "100%", outlined: "" }
            },
            [
              _c(
                "v-list-item",
                { attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "text-h4 mb-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("trackedWebCamComponent.error.title"))
                        )
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("trackedWebCamComponent.error.subtitle")
                          )
                        )
                      ]),
                      _c("v-list-item-content", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("trackedWebCamComponent.error.content.1")
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            _vm.$t("trackedWebCamComponent.error.content.2")
                          ) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(
                            _vm.$t("trackedWebCamComponent.error.content.3")
                          ) + " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-avatar",
                    { attrs: { tile: "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/images/noWebcamIcon.png")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cameraLoading,
              expression: "cameraLoading"
            }
          ],
          staticStyle: { height: "100%", width: "100%" },
          attrs: { align: "center", justify: "center" }
        },
        [
          _c(
            "v-progress-circular",
            {
              class: _vm.$vuetify.breakpoint.smAndDown
                ? "text-body-1"
                : "text-h5",
              attrs: {
                indeterminate: "",
                size: _vm.progressSize,
                width: _vm.progressWidth,
                color: "secondary"
              }
            },
            [_vm._v(_vm._s(_vm.loadingMessage))]
          )
        ],
        1
      ),
      _vm.availableInputDevices.length > 1
        ? _c(
            "v-col",
            { staticClass: "pa-0 ma-0", attrs: { cols: "8" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.availableInputDevices,
                  "item-text": "label",
                  "no-data-text": _vm.$t(
                    "trackedWebCamComponent.cameraDropDown.noCameras"
                  ),
                  "item-value": "id",
                  outlined: "",
                  "prepend-inner-icon": "mdi-camera",
                  label: _vm.$t("trackedWebCamComponent.cameraDropDown.label")
                },
                on: {
                  change: function($event) {
                    return _vm.loadCamera(_vm.currentInputDeviceId)
                  }
                },
                model: {
                  value: _vm.currentInputDeviceId,
                  callback: function($$v) {
                    _vm.currentInputDeviceId = $$v
                  },
                  expression: "currentInputDeviceId"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }