import DeskCalculations from "@/services/deskassessment/desk-calculations.js";

export default {
  areEyesInView(pose) {
    var leftEye = this.getKeyPoint(pose, "leftEye");
    var rightEye = this.getKeyPoint(pose, "rightEye");
    return leftEye.score > 0.5 && rightEye.score > 0.5;
  },
  areEarsInView(pose) {
    var leftEar = this.getKeyPoint(pose, "leftEar");
    var rightEar = this.getKeyPoint(pose, "rightEar");
    return leftEar.score > 0.5 && rightEar.score > 0.5;
  },
  areShouldersInView(pose) {
    var leftShoulder = this.getKeyPoint(pose, "leftShoulder");
    var rightShoulder = this.getKeyPoint(pose, "rightShoulder");
    return leftShoulder.score > 0.5 && rightShoulder.score > 0.5;
  },
  isSkeletonValid(pose) {
    var leftEye = this.getKeyPoint(pose, "leftEye");
    var rightEye = this.getKeyPoint(pose, "rightEye");
    var leftShoulder = this.getKeyPoint(pose, "leftShoulder");
    var rightShoulder = this.getKeyPoint(pose, "rightShoulder");

    var distanceBetweenEyes = DeskCalculations.getAbsoluteDistanceBetweenTwoPoints(
      leftEye,
      rightEye
    );
    var distanceBetweenShoulders = DeskCalculations.getAbsoluteDistanceBetweenTwoPoints(
      leftShoulder,
      rightShoulder
    );

    // 7.5 best value after testing - any lower and it ruins regular experience.
    var reasonableDistance =
      distanceBetweenShoulders / distanceBetweenEyes <= 7.5;

    var shoulderOnCorrectSideOfEye =
      leftShoulder.position.x > leftEye.position.x &&
      rightShoulder.position.x < rightEye.position.x;

    var shouldersBelowEyes =
      leftShoulder.position.y > leftEye.position.y &&
      rightShoulder.position.y > leftEye.position.y &&
      leftShoulder.position.y > rightEye.position.y &&
      rightShoulder.position.y > rightEye.position.y;

    return (
      reasonableDistance && shoulderOnCorrectSideOfEye && shouldersBelowEyes
    );
  },
  getKeyPoint(pose, partName) {
    return pose.keypoints.find(item => item.part === partName);
  }
};
